<template>
  <q-form ref="formRef" greedy>
    <q-select
      lazy-rules
      outlined
      dense
      option-value="_id"
      option-label="label"
      emit-value
      map-options
      v-model="form.user_id"
      use-input
      :label="$t('label.audience.user_name')"
      :options="filterOptions"
      @filter="filterEmailFn"
      :rules="[(val) => val && val?.length > 0]"
      v-if="form.copy_app_id && form._id == ''"
    >
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template></q-select
    >
    <q-input
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || requiredRule.appName]"
      outlined
      v-model="form.name"
      dense
      :placeholder="$t('label.app_name')"
      class="text-h6 text-bold"
    />

    <label class="text-grey-9 text-bold"> {{ $t('label.chanel_access_token') }}</label>
    <q-input
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || requiredRule.chanelAccessToken]"
      outlined
      v-model="form.token"
      dense
      :placeholder="$t('label.chanel_access_token')"
    />

    <label class="text-grey-9 text-bold">{{ $t('label.chanel_secret') }} </label>
    <q-input
      lazy-rules
      :rules="[(val) => (val && val.length > 0) || requiredRule.chanelSecret]"
      outlined
      v-model="form.secret"
      dense
      :placeholder="$t('label.chanel_secret')"
    />
    <div class="q-pb-sm">
      <div class="title">{{ $t('label.traffic_source.liff_id') }}</div>
      <label class="sub-title">LINE Developersから取得したLIFF IDを入力してください</label>
      <q-input lazy-rules outlined v-model="form.liff_id" dense :placeholder="$t('label.traffic_source.liff_id')" />
    </div>
    <div class="text-grey-6" v-if="!isNew">
      <p>{{ $t('label.app_url') }}</p>
      <q-badge outline align="middle" color="primary">
        {{ getWebhookURL(form.app_prefix) }}
      </q-badge>
    </div>
    <div class="text-grey-6" v-if="!isNew && form.account_id">
      <p>{{ $t('label.line_profile') }}</p>
      <q-badge outline align="middle" color="primary">
        {{ getLINEProfile(form.account_id) }}
      </q-badge>
    </div>
    <div class="text-grey-6" v-if="!isNew && form.account_id">
      <p>{{ $t('label.qr_code') }}</p>
      <q-avatar rounded size="100px">
        <img :src="getQRCode(form.account_id)" />
      </q-avatar>
    </div>

    <q-space class="q-my-md" />
    <div class="q-gutter-xs">
      <q-toggle round v-model="form.is_test_mode" :label="$t('label.test_mode')" />
    </div>
    <div class="q-gutter-xs" v-if="form.is_test_mode">
      <label class="text-grey-9 text-bold"> {{ $t('label.chanel_access_token_test_mode') }}</label>
      <q-input
        lazy-rules
        outlined
        v-model="form.token_test"
        :rules="[(val) => (val && val.length > 0) || requiredRule.chanelAccessTokenTest]"
        dense
        :placeholder="$t('label.chanel_access_token_test_mode')"
      />

      <label class="text-grey-9 text-bold">{{ $t('label.chanel_secret_test_mode') }} </label>
      <q-input
        lazy-rules
        outlined
        v-model="form.secret_test"
        :rules="[(val) => (val && val.length > 0) || requiredRule.chanelSecretTest]"
        dense
        :placeholder="$t('label.chanel_secret_test_mode')"
      />

      <div class="q-pb-sm">
        <div class="title">LIFF ID（テストモード）</div>
        <label class="sub-title">LINE Developersから取得したLIFF IDを入力してください</label>
        <q-input
          lazy-rules
          outlined
          v-model="form.liff_id_test"
          dense
          :placeholder="$t('label.traffic_source.liff_id')"
        />
      </div>

      <div class="text-grey-6" v-if="!isNew">
        <p>{{ $t('label.app_url_test_mode') }}</p>
        <q-badge outline align="middle" color="primary">
          {{ getWebhookURLTest(form.app_prefix) }}
        </q-badge>
      </div>
      <div class="text-grey-6" v-if="!isNew && form.account_id_test">
        <p>{{ $t('label.line_profile') }}</p>
        <q-badge outline align="middle" color="primary">
          {{ getLINEProfile(form.account_id_test) }}
        </q-badge>
      </div>
      <div class="text-grey-6" v-if="!isNew && form.account_id_test">
        <p>{{ $t('label.qr_code') }}</p>
        <q-avatar rounded size="100px">
          <img :src="getQRCode(form.account_id_test)" />
        </q-avatar>
      </div>
    </div>
    <q-space class="q-my-md" />

    <div class="row no-wrap items-center">
      <q-space />
      <div class="q-gutter-sm">
        <q-btn no-caps flat color="white" text-color="black" @click="onCancel" v-if="inModal">{{ $t('cancel') }}</q-btn>
        <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
        <q-btn no-caps v-if="userInfo?.canWriteApp && !isNew" color="red" @click="onDelete(form)">
          {{ $t('deletd_this_app') }}
        </q-btn>
      </div>
    </div>
  </q-form>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp, IUser } from '@/utils/types'
import { ACTION_APP, ACTION_USER } from '@/store/actions'
import { UserModel } from '@/models/user-model'

import AppMixin from './mixins/AppMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onCancel', 'update:onSave'],
})
export default class AppForm extends mixins(AppMixin) {
  @Prop({})
  modelValue!: IApp

  filterOptions: IUser[] = []

  @Watch('$store.getters.users')
  users() {
    const result: IUser[] = cloneDeep(this.$store.getters.users)
    result.forEach((element) => {
      element.label = element.display_name + ' || ' + element.email
    })
    return result
  }

  get form() {
    return this.modelValue
  }

  set form(value: IApp) {
    this.$emit('update:modelValue', value)
  }

  @Prop({ default: true })
  inModal!: boolean

  get requiredRule() {
    const requiredRule = {
      appName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app_name'),
      }),
      chanelAccessToken: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token'),
      }),
      chanelSecret: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret'),
      }),
      chanelAccessTokenTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token_test_mode'),
      }),
      chanelSecretTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret_test_mode'),
      }),
    }
    return requiredRule
  }

  get isNew() {
    return !this.form?._id
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  async filterEmailFn(val: string, update: Function) {
    const searchKey = val.toLowerCase()
    update(async () => {
      if (!val) {
        this.filterOptions = this.users()
      } else {
        this.filterOptions = this.users().filter((p) => p.label?.indexOf(searchKey) !== -1)
      }
    })
  }

  getLINEProfile(account_id) {
    const url = `https://page.line.me/?accountId=${account_id}&openQrModal=true`
    return url
  }

  getQRCode(account_id) {
    const url = `https://qr-official.line.me/sid/m/${account_id}.png`
    return url
  }

  getWebhookURL(app_prefix) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/${app_prefix}`
    return url
  }

  getWebhookURLTest(app_prefix) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/test/${app_prefix}`
    return url
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    if (this.isNew) {
      success = await dispatch(ACTION_APP.ADD_NEW, {
        ...this.form,
      })
    } else {
      success = await dispatch(ACTION_APP.UPDATE, {
        _id: this.form._id,
        ...this.form,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.$emit('update:onSave')
  }

  @Emit('update:onCancel')
  onCancel() {
    return true
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }

  async created() {
    await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.filterOptions = this.users()
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: bold;
}

.sub-title {
  font-size: 8px !important;
}

.horizontal-line-dotted {
  border-bottom: 1px dotted #d0d2e0;
}

.vertical-line {
  border-right: 1px dotted #d9d9d9;
}

.underline-link {
  text-decoration: underline;
}
</style>
